/** COLORS */

/* Primary Color */
// $primary-color: #122377; /* before */
$primary-color: #002677;
$secondary-color: #196ECF;
$color-white: #fff;
// $tertiary-color: #00BED5;

/* Supporting Color */
$uhc-bright-blue: #00BED5;
$uhc-gold: #F5B700;
$uhc-orange: #FF681F;

/* Limited use for digital spaces */
$uhc-cta-orange: #D14600;
$uhc-cta-dark-orange: #AA3600;
$uhc-cta-blue: $secondary-color;

/* Tints */
$primary-tint: #E5F8FB;
$light-gray-tint: #f4f4f4;
$medium-gray-tint: #DADBDC;

/* Status Indicator */
$success: #21b01e;
$info: #00A8F7;
$danger: #E91B18;
$warning: #AA3600;

/* Ratings */
$color-orange: #FFAA25;
$color-teal: #00BCD6;
$yellow-base: #FFD00D;
$orange-base: #FF5F0E;
$color-darkorange: #D53704;


/* Extras */
$color-green: $success;
/* Dark Gray */
$uhc-dark-gray: #5A5A5A;
/* Text Color */
$color-text: #333;
/* Link Default */
$link-default: #196ecf;
/* Grid Header Color */
$grid-header: $medium-gray-tint;
