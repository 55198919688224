// Variables :)
:root {
    --bs-font-sans-serif: "UHC Sans Medium", "Open Sans Regular", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
}
$theme-colors: (
  "primary": #002677,
  "danger": #ff4136,
  "accent-blue": #00bed5,
  "accent-gold": #f5b700,
  "accent-orange": #ff681f,
  "tint-blue-20": #ccf2f7,
  "tint-blue-40": #99e5ee,
  "tint-blue": #e5f8fb,
  "tint-light-gray": #f1f2f2,
  "tint-medium-gray": #dadbdc,
  "cta-orange": #d14600,
  "cta-dark-orange": #aa3600,
  "cta-blue": #196ecf
);
$border-radius: 1.5rem;
$border-width: 2px;

  // Change the Breadcrumb divider here:
  // $breadcrumb-divider: quote(">");

// Import stylesheets from node
@import '../../../../../node_modules/bootstrap/scss/bootstrap.scss';

// modal overrides 
.modal-content {
    border: none;
}

.form-control{
  border-radius: 3px;
}
