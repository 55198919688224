// .dropbtn {
//     background-color: #04AA6D;
//     color: white;
//     padding: 16px;
//     font-size: 16px;
//     border: none;
//     cursor: pointer;
//   }

//   .dropbtn:hover, .dropbtn:focus {
//     background-color: #3e8e41;
//   }

//   #local-authorities-country-input {
//     box-sizing: border-box;
//     background-image: url('searchicon.png');
//     background-position: 14px 12px;
//     background-repeat: no-repeat;
//     font-size: 16px;
//     padding: 14px 20px 12px 45px;
//     border: none;
//     border-bottom: 1px solid #ddd;
//   }

//   #local-authorities-country-input:focus {outline: 3px solid #ddd;}

.dropdown {
  position: relative;
  display: block;
  margin-top: -1px;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #FFF;
  // min-width: 230px;
  width: 100%;
  overflow: auto;
  border: 2px solid #ced4da;
  z-index: 1;
  margin: 0;
}

.dropdown-content a {
  color: black;
  padding: 5px 14px;
  text-decoration: none;
  display: block;
}

.dropdown a:hover {
  background-color: $secondary-color;
  color: #fff;
}

.dropdown a:focus {
  background-color: $secondary-color;
  color: #fff;
}

.show {
  display: block;
}

span.deleteicon {
  position: relative;
  display: inline-flex;
  align-items: center;
  width: 100%;
}
span.deleteicon span {
  position: absolute;
  display: block;
  top: 1px;
  right: 3px;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  color: #ccc;
  // background-color: #ccc;
  // font: 13px monospace;
  text-align: center;
  line-height: 1em;
  cursor: pointer;
  margin: 10px 5px;
}
span.deleteicon input {
  padding-right: 18px;
  box-sizing: border-box;
}