.list-links, .list-form-input, .list-selection {
    list-style: none;
    padding: 0;
    margin: 0;
    li {
        display: block;
        padding: 0;
        margin: 0;
        border-bottom: 1px #dadbdc solid;
        &:first-child {
            border-top: 1px #dadbdc solid;
        }
    }
}
.list-links {
    a {
        display: block;
        text-decoration: none;
        padding: 0.5em;
        color: $color-text;
        background-image: url("../images/chevron-default.svg");
        background-position: right 0.75em center;
        background-repeat: no-repeat;
        background-size: 0.6em auto;
        background-color: none;
        transition: linear 0.25s;
    }
    a:hover, a:active {
        color: #fff;
        background-image: url("../images/chevron-white.svg");
        background-color: $secondary-color;
    }

    &.text-content {
        a {
            display: block;
            background: none;
            color: $secondary-color;
        }
        a:hover, a:active {
            text-decoration: underline;
            color: $secondary-color;
            background-color: none;
        }
        p {
            font-size: 0.85em;
            padding: 0 0.5em 0.5em;
            margin-bottom: 0;
        }
    }

    p {
        padding: 0 0.5em;
    }

    &.switches {
        li {
            padding: 0.5em;
        }
    }
}

.list-form-input {
    &-item label {
        display: flex;
        justify-content: space-between;
        align-items: baseline;
        padding: 0.5em 1em 0.5em 0.5em;
        cursor: pointer;
        span {
            display: inline-block;
        }
        input {
            display: inline-block;
        }
    }
}

.list-selection {
    li {
        padding: 0 0 0 0.5em;
        display: flex;
        justify-content: space-between;
        .list-selection-label {
            padding: 0.5em 0;
        }
        .btn.remove {
            // @media (max-width: 400px) {
            //     min-width: 40px;
            //     max-width: 44px;
            //     margin-top: 0;
            //     padding-right: 0;
            // }
            min-width: 40px;
            max-width: 44px;
            margin-top: 0;
            padding-right: 0;
        }
    }
}

.list-links-alert-country {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
        display: block;
        padding: 0;
        margin: 0;
        cursor: pointer;
        border-bottom: 1px #dadbdc solid;
        &:first-child {
            border-top: 1px #dadbdc solid;

            
        }
        &:hover, &:active {
            color: #fff;
            background-color: $secondary-color;
            transition: linear 0.25s;
            a{
                color: #fff;
            }
        }
        
    }
    a {
        display: block;
        text-decoration: none;
        padding: 0.5em;
        color: $color-text;
        // background-image: url("../images/chevron-default.svg");
        background-position: right 0.75em center;
        background-repeat: no-repeat;
        background-size: 0.6em auto;
        background-color: none;
        transition: linear 0.25s;
    }
    a:hover, a:active {
        color: #fff;
        // background-image: url("../images/chevron-white.svg");
        background-color: $secondary-color;
    }

    &.text-content {
        a {
            display: block;
            background: none;
            color: $secondary-color;
        }
        a:hover, a:active {
            text-decoration: none;
            color: #fff;
            background-color: none;
        }
        p {
            font-size: 0.85em;
            padding: 0 0.5em 0.5em;
            margin-bottom: 0;
        }
    }

    p {
        padding: 0 0.5em;
    }

    &.switches {
        li {
            padding: 0.5em;
        }
    }
}