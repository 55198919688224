.loader {
    position: fixed;
    display: none;
    width: 100%;
    height: 100%;
    top: 0; 
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.1);
    z-index: 9998;
  }

.loader-container {
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    width: 350px;
    padding: 15px;
  }

  .loader-animation {
    display: inline-block;
    border: 16px solid #f3f3f3;
    border-radius: 50%;
    border-top: 13px solid $primary-color;
    border-bottom: 13px solid transparent;
    border-left: 13px solid $primary-color;
    border-right: 13px solid transparent;
    width: 120px;
    height: 120px;
    -webkit-animation: spin .5s linear infinite;
    animation: spin .5s linear infinite;
  }
  
  @-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }