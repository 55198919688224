/** Fonts **/
@font-face {
    font-family: 'Open Sans Regular';
    src: url('../fonts/Open_Sans/OpenSans-Regular.eot');
    /* IE9 Compat Modes */
    src: url('../fonts/Open_Sans/OpenSans-Regular.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */
        /* url('../fonts/Open_Sans/OpenSans-Regular.woff') format('woff'), /* Modern Browsers */
        url('../fonts/Open_Sans/OpenSans-Regular.ttf') format('truetype'),
        /* Safari, Android, iOS */
        /* url('../fonts/Open_Sans/OpenSans-Regular.svg#svgFontName') format('svg'); /* Legacy iOS */
}

@font-face {
    font-family: 'UHC Sans';
    src: url('../fonts/UHC_Sans/UHCSans-Regular.eot');
    /* IE9 Compat Modes */
    src: url('../fonts/UHC_Sans/UHCSans-Regular.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */
        url('../fonts/UHC_Sans/UHCSans-Regular.woff') format('woff'),
        /* Modern Browsers */
        url('../fonts/UHC_Sans/UHCSans-Regular.ttf') format('truetype'),
        /* Safari, Android, iOS */
        url('../fonts/UHC_Sans/UHCSans-Regular.svg#svgFontName') format('svg');
    /* Legacy iOS */
}

@font-face {
    font-family: 'UHC Sans Regular';
    src: url('../fonts/UHC_Sans/UHCSans-Regular.eot');
    /* IE9 Compat Modes */
    src: url('../fonts/UHC_Sans/UHCSans-Regular.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */
        url('../fonts/UHC_Sans/UHCSans-Regular.woff') format('woff'),
        /* Modern Browsers */
        url('../fonts/UHC_Sans/UHCSans-Regular.ttf') format('truetype'),
        /* Safari, Android, iOS */
        url('../fonts/UHC_Sans/UHCSans-Regular.svg#svgFontName') format('svg');
    /* Legacy iOS */
}

@font-face {
    font-family: 'UHC Sans Medium';
    src: url('../fonts/UHC_Sans/UHCSans-Medium.eot');
    /* IE9 Compat Modes */
    src: url('../fonts/UHC_Sans/UHCSans-Medium.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */
        url('../fonts/UHC_Sans/UHCSans-Medium.woff') format('woff'),
        /* Modern Browsers */
        url('../fonts/UHC_Sans/UHCSans-Medium.ttf') format('truetype'),
        /* Safari, Android, iOS */
        url('../fonts/UHC_Sans/UHCSans-Medium.svg#svgFontName') format('svg');
    /* Legacy iOS */
}

@font-face {
    font-family: 'UHC Sans SemiBold';
    src: url('../fonts/UHC_Sans/UHCSans-SemiBold.eot');
    /* IE9 Compat Modes */
    src: url('../fonts/UHC_Sans/UHCSans-SemiBold.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */
        url('../fonts/UHC_Sans/UHCSans-SemiBold.woff') format('woff'),
        /* Modern Browsers */
        url('../fonts/UHC_Sans/UHCSans-SemiBold.ttf') format('truetype'),
        /* Safari, Android, iOS */
        url('../fonts/UHC_Sans/UHCSans-SemiBold.svg#svgFontName') format('svg');
    /* Legacy iOS */
}

@font-face {
    font-family: 'UHC Sans Bold';
    src: url('../fonts/UHC_Sans/UHCSans-Bold.eot');
    /* IE9 Compat Modes */
    src: url('../fonts/UHC_Sans/UHCSans-Bold.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */
        url('../fonts/UHC_Sans/UHCSans-Bold.woff') format('woff'),
        /* Modern Browsers */
        url('../fonts/UHC_Sans/UHCSans-Bold.ttf') format('truetype'),
        /* Safari, Android, iOS */
        url('../fonts/UHC_Sans/UHCSans-Bold.svg#svgFontName') format('svg');
    /* Legacy iOS */
}

@font-face {
    font-family: 'UHC Serif';
    src: url('../fonts/UHC_Serif/UHCSerifTextWeb-Regular.eot');
    /* IE9 Compat Modes */
    src: url('../fonts/UHC_Serif/UHCSerifTextWeb-Regular.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */
        url('../fonts/UHC_Serif/UHCSerifTextWeb-Regular.woff') format('woff'),
        /* Modern Browsers */
        url('../fonts/UHC_Serif/UHCSerifTextWeb-Regular.ttf') format('truetype'),
        /* Safari, Android, iOS */
        url('../fonts/UHC_Serif/UHCSerifTextWeb-Regular.svg#svgFontName') format('svg');
    /* Legacy iOS */
}

@font-face {
    font-family: 'UHC Serif Semibold';
    src: url('../fonts/UHC_Serif/UHCSerifHeadlineApp-Semibold.ttf');
    /* IE9 Compat Modes */
    src: url('../fonts/UHC_Serif/UHCSerifHeadlineApp-Semibold.ttf?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */
        //url('../fonts/UHC_Serif/UHCSerifHeadlineApp-Semibold.woff') format('woff'), /* Modern Browsers */
        url('../fonts/UHC_Serif/UHCSerifHeadlineApp-Semibold.ttf') format('truetype'),
        /* Safari, Android, iOS */
        url('../fonts/UHC_Serif/UHCSerifHeadlineApp-Semibold.svg#svgFontName') format('svg');
    /* Legacy iOS */
}

body {
    // font-family: 'Open Sans Regular', Arial, sans-serif;
    font-family: 'UHC Sans Medium', 'Open Sans Regular', sans-serif;
    color: $color-text;
}

/** Headers **/
h1,
.h1,
h2,
.h2,
h3,
.h3 {
    margin-bottom: 20px;
}

h1 {
    //font-family: 'UHC Sans Regular', Arial, sans-serif;
    font-family: 'UHC Serif Semibold', Georgia, sans-serif;
    font-size: 1.125rem;
    color: $primary-color;

    // @media screen and (max-width: 767px) {
    //     font-size: 1.802rem;
    // }
}

h2,
h3 {
    //font-family: 'UHC Sans Medium', Arial, sans-serif;
    font-family: 'UHC Serif Semibold', Georgia, sans-serif;
    color: $primary-color;
}

h4,
h5,
h6 {
    font-family: 'UHC Sans Bold', Arial, sans-serif;
}

h2 {
    font-size: 2.017rem;
    // @media screen and (max-width: 767px) {
    //     font-size: 1.609rem;
    // }
}

h3 {
    font-size: 1.802rem;
    // @media screen and (max-width: 767px) {
    //     font-size: 1.424rem;
    // }
}

h4 {
    font-size: 1rem;
    // @media screen and (max-width: 767px) {
    //     font-size: 1.266rem;
    // }
}

h5 {
    font-size: 1.424rem;
    // @media screen and (max-width: 767px) {
    //     font-size: 1.125rem;
    // }
}

h6 {
    font-size: 1.266rem;
    // @media screen and (max-width: 767px) {
    //     font-size: 1rem;
    // }
}

// h1, h2, h3 {
//     color: $primary-color;
// }

.info-text {
    font-size: 14px;
    line-height: 1.1;
}

.executive-summary-title {
    h1 {
        text-align: left !important;
    }
}