/* Indicators */
.status-indicator {
    display: inline-block;
    padding: 0 $spacing/3;
    text-transform: uppercase;
    border-radius: $spacing/5;
    color: #fff;
    font-size: 0.75em;
    font-weight: bold;
    background-color: #444444;

    &.success {
        background-color: $success;
    }
    &.info {
        background-color: $info;
    }
    &.danger {
        background-color: $danger;
    }
    &.warning {
        background-color: $warning;
    }
}
