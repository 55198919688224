.mobileviews {
    padding-left: 0;
    padding-right: 0;
}
.header-links {
    // display: flex;
    // justify-content: space-between;
    // align-items: center;

    h1, h2, h3, h4, h5, h6 {
        text-align: center;
    }

    .link-back {
        max-width: 100px;
    }

    .link-back:link, 
    .link-back:visited,
    .link-download:link,
    .link-download:visited {
        display: block;
        text-decoration: none;
        color: $secondary-color;
        background-color: none;
    }
    .link-back:hover, 
    .link-back:active,
    .link-download:hover, 
    .link-download:active {
        background-color: lighten($secondary-color, 40%);
    }
    .link-back {
        background-image: url("../images/chevron-blue-secondary.svg");
        background-position: left 0.75em center;
        background-repeat: no-repeat;
        background-size: 0.6em auto;
        background-color: none;
        transition: linear 0.25s;
    }
}

// expat mobile tabs
.expatmobile-tabs {
    .nav-link {
        border-radius: 0;
        background-color: #f4f4f4;
        color: #6f6f6f;
        text-transform: uppercase;
        font-size: 1.2em;
        &.active {
            background-color: #fff;
            border-bottom: 2px $secondary-color solid;
            color: $primary-color;
        }
    }
}

.iconed {
    padding: 1em 0 1em 2em;
    // min-height: 32px;
    &.icon-alert {
        background: url("../images/Icon_Alert_2C_CirBlue_RGB.png") top 16px left no-repeat;
        background-size: 24px auto;
    }
}

.button-icon {
    svg {
        // fill: lighten($color-text, 50%);
        width: auto;
        height: 16px;
        path {
            fill: lighten($color-text, 50%);
        }
    }
}

/* Page - Alerts by Country */

.separator-text {
    background-color: $secondary-color;
    padding: 0.25em 0.5em;
    color: #fff;
    margin-bottom: 0;
}

.alert-country-selector {
    input {
        visibility: hidden;
    }
}

/* Feedback */
.ratings-legend {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    p {
        width: 20%;
        font-size: 0.75em;
        &:last-child {
            text-align: right;
        }
    }
}
.ratings-selection {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    label.btn-cta-blue {
        font-size: 0.6em;
        padding: 3px;
        min-width: 24px;
        background-color: #fff;
        color: $secondary-color;
        border: 2px #196ecf solid;
    }
    label.btn-cta-blue:hover, label.btn-cta-blue:active {
        background-color: $secondary-color;
        color: #fff;
        border: 2px $secondary-color solid;
    }
}

.page-action-sticky {
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: #fff;
    @media screen and (min-width: 992px) {
        position: relative;
        bottom: initial;
    }
}