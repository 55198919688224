/** Buttons */
/* color guide 

// /* Primary Color */
// // $primary-color: #122377; /* before */
// $primary-color: #002677;
// $secondary-color: #196ECF;
// $color-white: #fff;
// // $tertiary-color: #00BED5;

// /* Supporting Color */
// $uhc-bright-blue: #00BED5;
// $uhc-gold: #F5B700;
// $uhc-orange: #FF681F;

// /* Limited use for digital spaces */
// $uhc-cta-orange: #D14600;
// $uhc-cta-dark-orange: #AA3600;
// $uhc-cta-blue: #196ecf;

// /* Tints */
// $primary-tint: #E5F8FB;
// $light-gray-tint: #F1F2F2;
// $medium-gray-tint: #DADBDC;

// /* Dark Gray */
// $uhc-dark-gray: #5A5A5A;

// /* Text Color */
// $color-text: #5A5A5A;

// /* Link Default */
// $link-default: #196ecf;


.btn {
    // text-transform: capitalize;
    font-size: 14px;
    border-radius: 1.32rem;
    font-size: 0.95rem;
    min-width: 92px;
    // min-height: 42px;
    padding: .57rem 1.5rem;

    &:focus {
        color: $color-white;
    }

    @media (max-width: 400px) {
        width: 100%;
        margin-top: 10px;
    }
}

.btn-default, .btn-primary, .btn-success, .btn-info, .btn-warning, .btn-danger {
    background-image: none;
}

.btn-primary{
    font-family: 'UHC Sans Bold', Arial, sans-serif;
}

/* Emphasis */
@mixin button-bg($bg) {
    background: $bg;
    margin: 1px;
    &:hover {
        background: $secondary-color;
        transition: all 0.3s ease;
        color: $color-white;
        border: $bg 1px solid;
    }
    &:active, &:focus {
        background:lighten($bg,15%);
        color: lighten($bg, 100%)
    }
    &:disabled {
        background: $light-gray-tint;
        color: $medium-gray-tint;
    }
    
}

/* Standard */
@mixin button-bg-hollow($bg) {
    background: $color-white;
    border: $bg 1px solid;
    color: $primary-color;
    &:hover {
        background: $primary-color;
        transition: all 0.3s ease;
        color: $color-white;
    }
    // &:active {
    //     background:darken($bg,15%);
    //     color: lighten($bg, 100%)
    // } 
    &:focus {
        background: $primary-color;
        transition: all 0.3s ease;
        color: $color-white;
        //color: #fff;
    }
}


/* Emphasis */
.btn-primary-emphasis {
    color: #fff;
    @include button-bg($primary-color);

    &.btn-grp {
        padding: .3rem .75rem;
    }

    @media screen and (max-width: 767px) {
        width: 100%;
        margin-bottom: 10px;
    }
}

/* Standard Buttons */
.btn-primary-standard {
    @include button-bg-hollow($primary-color);

    @media screen and (max-width: 767px) {
        width: 100%;
        margin-bottom: 10px;
    }
}

/* Call to Action */
.btn-call-to-action {
    color: $color-white;
    @include button-bg($uhc-cta-orange);

    &:hover {
        background: $uhc-cta-dark-orange;
    }
}

/* Hover and focus states */
.btn-gray:hover {
    background: #fff;
    color: #444;
    border: #8c9599 1px solid;
}

.btn-gray:focus {
    background: #F5F5F5;
    color: #444444;
}

[type=button], [type=reset], [type=submit], button {
    -webkit-appearance: initial;
}

.back-to-top, .header-icons {
    color: $uhc-dark-gray;
}

a[type=button] {
    -webkit-appearance: initial;
    text-decoration: none;
}

/* disabled buttons */
.btn.disabled,
.btn:disabled,
.btn-primary.disabled,
.btn-primary:disabled {
    background-color: lighten($color-text, 70%) !important;
    border-color: lighten($color-text, 70%) !important;
    color: lighten($color-text, 30%) !important;
    opacity: 1 !important;
}