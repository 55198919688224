/* Variables and Mixins */
@import 'helpers/variables';
// @import 'helpers/mixins';
// @import 'helpers/helper';

/* Colors, Typography, Buttons */
@import 'base/colors';
@import 'base/typography';
@import 'base/icons';
@import 'components/buttons';

/* Third-party */
// @import 'vendor/font-awesome/fontawesome';
// @import 'vendor/font-awesome/fa-solid';
// @import 'vendor/font-awesome/fa-regular';

/* Layout */
// @import 'layout/body';
// @import 'layout/grid';
// @import 'layout/header';
// @import 'layout/footer';
// @import 'layout/forms';
// @import 'layout/info-country';

/* Components */
@import 'components/lists';
@import 'components/loader';
@import 'components/dropdown-search';

// @import 'components/containers';
// @import 'components/tabs';
// @import 'components/navbar';
// @import 'components/page-actions';
// @import 'components/logo';
// @import 'components/tags';
// @import 'components/tooltip';
// @import 'components/jquery-dialog';
// @import 'components/scroll-to-top';
// @import 'components/recent-events';
// @import 'components/badges';
// @import 'components/subnav';
// @import 'components/risk-rating';
// @import 'components/cards';
// @import 'components/bing-maps';
// @import 'components/flags';
// @import 'components/links';
// @import 'components/uhc-icons';
// @import 'components/uhc-pagination';
// @import 'components/side-nav';
// @import 'components/collapse';

/* Kendo UI components */
// @import 'components/kendo-button';
// @import 'components/kendo-datepicker';
// @import 'components/kendo-checkboxes';
// @import 'components/kendo-combobox';
// @import 'components/kendo-dropdown';
// @import 'components/kendo-autocomplete';
// @import 'components/kendo-multiselect';
// @import 'components/kendo-grid';
// @import 'components/kendo-grid-pagination';
// @import 'components/kendo-tabs';
// @import 'components/kendo-components-overrides';
// @import 'components/kendo-file-upload';
// @import 'components/kendo-switch';
// @import 'components/kendo-dialog';
// @import 'components/custom-tabs';
// @import 'components/breadcrumbs';

// IE Specific
//@import 'fallback/ie-specific';

// Bootstrap
@import 'bootstrap/custom-bootstrap.scss';

// Application Specific Styles
@import 'expatmobile/expatmobile.scss';
// Pages
@import 'pages/local-authorities.scss';